import React, { useEffect, useState } from "react";
import rootApi from "@/helpers/api/rootConfig";
import { useParams } from "react-router-dom";
import CustomerModal from "@/components/Modals/CustomerModal";
import FlashMessage from "@/components/FlashMessage";
import MainContainer from "@/components/mainContainer/MainContainer";
import WalletIcon from "@mui/icons-material/Wallet";
import LockIcon from "@mui/icons-material/Lock";
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridCsvExportOptions,
  GridCsvGetRowsToExportParams,
  GridPaginationModel,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  gridPaginatedVisibleSortedGridRowIdsSelector,
  useGridApiContext,
} from "@mui/x-data-grid";
import { gridLocale } from "@/helpers/interfaces/datagridLocale";
import NewFilter from "@/components/Filter/NewFilter";
import {
  Button,
  ButtonProps,
  Chip,
  createSvgIcon,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  TextField,
} from "@mui/material";
import { changeCustomerRole, getEventCustomers } from "@/helpers/api/customers";
import { ChoppbotCustomer } from "@/helpers/interfaces/customers";
import styles from "./ComandaCards.module.css";
import { downloadCSV } from "@/helpers/exportCustomers";
import { BeerReport } from "@/helpers/interfaces/beerReport";
import { eventStatusTranslation } from "@/helpers/eventStatusTranslation";
import { EmailOutlined, PhoneOutlined } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";

export default function Receipt() {
  const params = useParams();
  const [filterData, setFilterData] = useState({
    name: "",
    cpf: "",
    phone: "",
    status: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openPermissions, setOpenPermissions] = useState(false);
  const [flashMessage, setFlashMessage] = useState(false);
  const [selectedCustomerBeerReport, setSelectedCustomerBeerReport] =
    useState<BeerReport | null>(null);
  const [selectedCustomer, setSelectedCustomer] =
    useState<ChoppbotCustomer | null>(null);
  const [role, setRole] = useState<ChoppbotCustomer["permission"]>("client");
  const [isChangingRole, setIsChangingRole] = useState(false);
  const [totalRowCount, setTotalRowCount] = useState(0);
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: 10,
  });
  const [dataCustomer, setDataCustomer] = useState<ChoppbotCustomer[]>([]);
  const [rowCountState, setRowCountState] = React.useState(totalRowCount);
  useEffect(() => {
    setRowCountState((prevRowCountState) =>
      totalRowCount !== undefined ? totalRowCount : prevRowCountState
    );
  }, [totalRowCount, setRowCountState]);

  useEffect(() => {
    async function fetchData() {
      const futureCustomers = await getEventCustomers({
        eventId: params.id!,
        page: 1,
        pageSize: paginationModel.pageSize,
      });

      if (futureCustomers) {
        setDataCustomer(futureCustomers.data);
        setTotalRowCount(futureCustomers.meta.total);
      }
    }

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function filter(filterData: {
    name: string;
    cpf: string;
    phone: string;
    status: string;
  }) {
    const newCustomers = await getEventCustomers({
      eventId: params.id!,
      page: 1,
      pageSize: paginationModel.pageSize,
      nameFilter: filterData.name,
      phoneFilter: filterData.phone,
      cpfFilter: filterData.cpf,
      statusFilter: filterData.status,
    });
    if (newCustomers) {
      setDataCustomer(newCustomers.data);
      setPaginationModel({ page: 0, pageSize: paginationModel.pageSize });
      setTotalRowCount(newCustomers.meta.total);
    }
  }

  async function clearFilters() {
    const newCustomers = await getEventCustomers({
      eventId: params.id!,
      page: 1,
      pageSize: paginationModel.pageSize,
    });
    if (newCustomers) {
      setDataCustomer(newCustomers.data);
      setTotalRowCount(newCustomers.meta.total);
    }
  }

  async function onPaginationModelChange(model: GridPaginationModel) {
    setIsLoading(true);
    const newCustomers = await getEventCustomers({
      eventId: params.id!,
      page: model.page + 1,
      pageSize: model.pageSize,
    });
    if (newCustomers) {
      setDataCustomer(newCustomers.data);
      setPaginationModel(model);
    }
    setIsLoading(false);
  }

  function handleClosePermissions() {
    setOpenPermissions(false);
    setSelectedCustomer(null);
  }

  async function handleChangePermissions() {
    if (!selectedCustomer) return;

    setIsChangingRole(true);
    await changeCustomerRole({
      permission: role,
      customerId: selectedCustomer.id,
      event_id: selectedCustomer.event.id,
      franchisee_id: selectedCustomer?.franchisee.id,
    })
      .then(async (res) => {
        setOpenPermissions(false);
        setSelectedCustomer(null);
        setIsLoading(true);
        setFlashMessage(true);
        setTimeout(() => {
          setFlashMessage(false);
        }, 3000);
        const newCustomers = await getEventCustomers({
          eventId: params.id!,
          page: paginationModel.page + 1,
          pageSize: paginationModel.pageSize,
          nameFilter: filterData.name,
          phoneFilter: filterData.phone,
          cpfFilter: filterData.cpf,
          statusFilter: filterData.status,
        });
        if (newCustomers) setDataCustomer(newCustomers.data);
        setIsLoading(false);
      })
      .catch((err) => {});
    setIsChangingRole(false);
  }

  const fetchCustomerBeerReport = async (Code: number | string) => {
    rootApi
      .get<BeerReport>("api/beer_report/" + Code, {
        headers: { Event: params.id },
      })
      .then((response) => {
        setSelectedCustomerBeerReport(response.data);
      })
      .catch((err) => console.error(err));
  };

  const updatePayment = async (
    id: number,
    event_id_customer: string | number,
    isPaid: boolean
  ) => {
    if (!params.id) return;
    setIsLoading(true);

    rootApi
      .put("api/customer/" + id, {
        event_id: event_id_customer,
        payment: isPaid,
      })
      .then(async (response) => {
        if (response.data) {
          const newCustomers = await getEventCustomers({
            eventId: params.id!,
            page: paginationModel.page + 1,
            pageSize: paginationModel.pageSize,
            nameFilter: filterData.name,
            phoneFilter: filterData.phone,
            cpfFilter: filterData.cpf,
            statusFilter: filterData.status,
          });
          if (newCustomers) setDataCustomer(newCustomers.data);
          setFlashMessage(true);
          setTimeout(() => {
            setFlashMessage(false);
          }, 3000);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.error(err);
      });

    setOpenModal(false);
    setSelectedCustomerBeerReport(null);
  };

  const columns: GridColDef<ChoppbotCustomer>[] = [
    {
      field: "id",
      headerName: "ID",
      width: 60,
    },
    {
      field: "Client",
      headerName: "Cliente",
      minWidth: 190,
      flex: 1,
      renderCell(params) {
        return (
          <div style={{ display: "flex", alignItems: "center", flex: 1 }}>
            <img
              src={`https://www.choppbot.com.br${params.row.face.path}`}
              width={40}
              height={40}
              alt={params.row.name}
              style={{
                marginRight: "8px",
                borderRadius: "9999px",
                minWidth: "40px",
              }}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 1,
              }}
            >
              <span>{params.row.name}</span>
              <Chip
                sx={{ marginTop: "4px", maxWidth: "140px" }}
                size="small"
                color={
                  params.row.permission === "client" ? "default" : "info"
                }
                label={
                  params.row.permission === "client"
                    ? "Cliente"
                    : "Administrador"
                }
              />
            </div>
          </div>
        );
      },
    },
    {
      field: "Contact",
      headerName: "Contato",
      minWidth: 200,
      flex: 1,
      renderCell(params) {
        return (
          <div
            style={{
              height: "100%",
              display: "flex",
              paddingBlock: "4px",
              flexDirection: "column",
              justifyContent: "space-around",
            }}
          >
            {params.row.email && (
              <div style={{ display: "flex", alignItems: "center" }}>
                <EmailOutlined fontSize="small" sx={{ marginRight: "8px" }} />
                <span>{params.row.email}</span>
              </div>
            )}
            {params.row.phone && (
              <div style={{ display: "flex", alignItems: "center" }}>
                <PhoneOutlined fontSize="small" sx={{ marginRight: "8px" }} />
                <span>{params.row.phone}</span>
              </div>
            )}
          </div>
        );
      },
    },
    {
      field: "client_code",
      headerName: "No. Comanda",
    },
    {
      field: "identifier",
      headerName: "CPF",
      sortable: false,
      minWidth: 130,
    },
    {
      field: "company",
      headerName: "Empresa",
      minWidth: 120,
    },
    {
      field: "profession",
      headerName: "Cargo",
      minWidth: 120,
    },
    {
      field: "payment",
      headerName: "Pagamento",
      sortable: false,
      filterable: false,
      valueFormatter(params) {
        return params.value === 0 ? "Pendente" : "Pago";
      },
      renderCell: (params) => {
        return (
          <Chip
            color={params.value === 0 ? "warning" : "success"}
            label={params.value === 0 ? "Pendente" : "Pago"}
          />
        );
      },
    },
    {
      field: "action",
      headerName: "Ações",
      sortable: false,
      filterable: false,
      disableExport: true,
      type: "actions",
      getActions: (params) => [
        <GridActionsCellItem
          showInMenu
          icon={<WalletIcon />}
          label="Gerenciar comanda"
          onClick={() => {
            fetchCustomerBeerReport(params.row.id);
            setOpenModal(true);
          }}
        />,
        <GridActionsCellItem
          showInMenu
          onClick={() => {
            setSelectedCustomer(params.row);
            setRole(params.row.permission);
            setOpenPermissions(true);
          }}
          icon={<LockIcon />}
          label="Gerenciar cargo"
        />,
      ],
    },
  ];

  return (
    <MainContainer>
      <div className={styles.headerContainer}>
        <h2>
          {dataCustomer.length > 0
            ? dataCustomer[0].event.name
            : "Sem informações sobre o evento"}
        </h2>
        <div
          style={{
            width: "150px",
            height: "30px",
            borderRadius: "5px",
            backgroundColor:
              dataCustomer.length > 0 &&
              dataCustomer[0].event.status === "activated"
                ? "#74D47ED1"
                : "#F5C387D1",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <p>
            {dataCustomer.length > 0
              ? eventStatusTranslation(dataCustomer[0].event.status)
              : "---"}
          </p>
        </div>
      </div>
      <NewFilter
        filter={filter}
        filterData={filterData}
        clearFilters={clearFilters}
        setFilterData={setFilterData}
      />
      <div className={styles.tableContainer}>
        <DataGrid
          rowHeight={65}
          columns={columns}
          rows={dataCustomer}
          loading={isLoading}
          paginationMode="server"
          localeText={gridLocale}
          rowCount={rowCountState}
          disableRowSelectionOnClick
          paginationModel={paginationModel}
          initialState={{
            columns: {
              columnVisibilityModel: {
                id: false,
              },
            },
          }}
          onPaginationModelChange={onPaginationModelChange}
          pageSizeOptions={[10, 15, 25, 50, 100]}
          slots={{ toolbar: CustomToolbar }}
        />
      </div>
      {openModal && selectedCustomerBeerReport != null && (
        <CustomerModal
          open={openModal}
          beerReport={selectedCustomerBeerReport}
          onSend={updatePayment}
          onClose={() => {
            setOpenModal(false);
            setSelectedCustomerBeerReport(null);
          }}
        />
      )}
      <Dialog
        fullWidth
        maxWidth="sm"
        open={openPermissions}
        onClose={handleClosePermissions}
      >
        <DialogTitle>Gerenciar cargo de {selectedCustomer?.name}</DialogTitle>
        <DialogContent>
          <TextField
            select
            fullWidth
            value={role}
            label="Cargo"
            margin="dense"
            onChange={(ev) =>
              setRole(ev.target.value as ChoppbotCustomer["permission"])
            }
          >
            <MenuItem value="manager">Administrador</MenuItem>
            <MenuItem value="client">Cliente</MenuItem>
          </TextField>
        </DialogContent>
        <DialogActions>
          <Button color="error" onClick={handleClosePermissions}>
            Cancelar
          </Button>
          <LoadingButton
            loading={isChangingRole}
            onClick={handleChangePermissions}
          >
            Salvar
          </LoadingButton>
        </DialogActions>
      </Dialog>
      {flashMessage && (
        <FlashMessage
          message="Atualização salva com sucesso"
          title="Sucesso"
          type="success"
        />
      )}
    </MainContainer>
  );
}

const getRowsFromCurrentPage = ({ apiRef }: GridCsvGetRowsToExportParams) =>
  gridPaginatedVisibleSortedGridRowIdsSelector(apiRef);

const ExportIcon = createSvgIcon(
  <path d="M19 12v7H5v-7H3v7c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-7h-2zm-6 .67l2.59-2.58L17 11.5l-5 5-5-5 1.41-1.41L11 12.67V3h2z" />,
  "SaveAlt"
);

async function exportAllRows({ apiRef }: GridCsvGetRowsToExportParams) {
  const rowIds = apiRef.current.getAllRowIds();
  const allRows = await getEventCustomers({
    eventId: apiRef.current.getRow(rowIds[0]).event.id,
  });
  if (allRows) {
    downloadCSV(allRows.data);
  }
}

function CustomToolbar() {
  const apiRef = useGridApiContext();

  const handleExport = (options: GridCsvExportOptions) =>
    apiRef.current.exportDataAsCsv(options);

  const buttonBaseProps: ButtonProps = {
    color: "primary",
    size: "small",
    startIcon: <ExportIcon />,
  };

  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <Button
        {...buttonBaseProps}
        onClick={() =>
          handleExport({ getRowsToExport: getRowsFromCurrentPage })
        }
      >
        Exportar página atual
      </Button>
      <Button {...buttonBaseProps} onClick={() => exportAllRows({ apiRef })}>
        Exportar todos os clientes
      </Button>
    </GridToolbarContainer>
  );
}
