import styles from "./AddBeer.module.css";
import LoginAuth from "../../helpers/context/LoginContext";
import { useContext } from "react";
import { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";

function AddEventBeer(props) {
  const { user, baseURL } = useContext(LoginAuth);
  const [nameOneBeer, setNameOneBeer] = useState("");
  const [nameTwoBeer, setNameTwoBeer] = useState("");
  const [sizeOneBeer, setSizeOneBeer] = useState("");
  const [sizeTwoBeer, setSizeTwoBeer] = useState("");

  const AddNewBeers = async () => {
    console.log(nameOneBeer);
    console.log(nameTwoBeer);
    console.log(sizeOneBeer);
    console.log(sizeTwoBeer);

    const options1 = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + user.token,
      },
      body: JSON.stringify({
        franchisee_id: 1,
        event_id: props.eventID,
        beer_name: nameOneBeer,
        price_liters: sizeOneBeer,
        price_unit: sizeOneBeer,
      }),
    };

    const options2 = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + user.token,
      },
      body: JSON.stringify({
        franchisee_id: 1,
        event_id: props.eventID,
        beer_name: nameTwoBeer,
        price_liters: sizeTwoBeer,
        price_unit: sizeTwoBeer,
      }),
    };

    fetch(baseURL + "/api/beer", options1)
      .then((response) => response.json())
      .then((response) => console.log(response))
      .catch((err) => console.error(err));

    fetch(baseURL + "/api/beer", options2)
      .then((response) => response.json())
      .then((response) => console.log(response))
      .catch((err) => console.error(err));
  };

  return (
    <Dialog open={props.modal} onClose={props.close} maxWidth="sm" fullWidth>
      <DialogTitle>Criação de bebidas - Evento {props.title}</DialogTitle>
      <DialogContent>
        <div className={styles.inputsContainer}>
          <div className={styles.inputBox}>
            <TextField
              margin="dense"
              label="Nome da primeira cerveja"
              onChange={(ev) => setNameOneBeer(ev.target.value)}
            />

            <TextField
              margin="dense"
              label="Nome da segunda cerveja"
              onChange={(value) => setNameTwoBeer(value.target.value)}
            />
          </div>
          <div style={{ marginLeft: "12px" }} className={styles.inputBox}>
            <TextField
              margin="dense"
              placeholder="00.00"
              label="Preço por litro"
              InputProps={{
                startAdornment: <span style={{ marginRight: "4px" }}>R$</span>,
              }}
              onChange={(value) => setSizeOneBeer(value.target.value)}
            />
            <TextField
              margin="dense"
              placeholder="00.00"
              label="Preço por litro"
              InputProps={{
                startAdornment: <span style={{ marginRight: "4px" }}>R$</span>,
              }}
              onChange={(value) => setSizeTwoBeer(value.target.value)}
            />
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button color="error" onClick={props.close}>
          Cancelar
        </Button>
        <Button
          onClick={() => {
            AddNewBeers();
            setTimeout(() => {
              props.send();
            }, 3000);
          }}
        >
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AddEventBeer;
