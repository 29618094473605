import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

interface ConfirmModalProps {
  open: boolean;
  title: string;
  description?: string;
  isLoading?: boolean;
  onClose: () => void;
  onSubmit: () => void;
}

function ConfirmModal({
  open,
  title,
  description,
  isLoading,
  onClose,
  onSubmit,
}: ConfirmModalProps) {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      {!!description && (
        <DialogContent>
          <DialogContentText>{description}</DialogContentText>
        </DialogContent>
      )}
      <DialogActions>
        <Button onClick={onClose}>Cancelar</Button>
        <LoadingButton
          color="error"
          loading={isLoading}
          variant="contained"
          onClick={() => {
            onSubmit();
            onClose();
          }}
        >
          Remover
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmModal;
