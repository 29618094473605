import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import styles from "./AddBeer.module.css";
import LoginAuth from "../../helpers/context/LoginContext";
import { useContext } from "react";
import { useState } from "react";
import { ChoppbotBeer } from "@/helpers/interfaces/beer";

interface EditEventBeerProps {
  modal: boolean;
  title: string;
  beer: ChoppbotBeer;
  close: () => void;
  send: (success: boolean) => void;
}

function EditEventBeer({
  modal,
  title,
  beer,
  close,
  send,
}: EditEventBeerProps) {
  const { user, baseURL } = useContext(LoginAuth);
  const [beerName, setBeerName] = useState(beer.beer_name);
  const [beerUnitPrice, setBeerUnitPrice] = useState(
    beer.price_unit.toString()
  );

  const AddNewBeers = async () => {
    console.log(beerName);
    console.log(beerUnitPrice);

    const options1 = {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + user.token,
      },
      body: JSON.stringify({
        franchisee_id: 1,
        event_id: beer.event.id,
        beer_name: beerName,
        price_liters: beerUnitPrice,
        price_unit: beerUnitPrice,
      }),
    };

    fetch(baseURL + "/api/beer/" + beer.id, options1)
      .then(() => {
        send(true);
      })
      .catch(() => {
        send(false);
      });
  };

  return (
    <Dialog open={modal} onClose={close} maxWidth="sm" fullWidth>
      <DialogTitle>Edição de bebida - Evento {title}</DialogTitle>
      <DialogContent>
        <div className={styles.inputsContainer}>
          <div className={styles.inputBox}>
            <TextField
              margin="dense"
              value={beerName}
              label="Nome da cerveja"
              onChange={(ev) => setBeerName(ev.target.value)}
            />
          </div>
          <div style={{ marginLeft: "12px" }} className={styles.inputBox}>
            <TextField
              margin="dense"
              placeholder="00.00"
              value={beerUnitPrice}
              label="Preço por litro"
              InputProps={{
                startAdornment: <span style={{ marginRight: "4px" }}>R$</span>,
              }}
              onChange={(ev) => setBeerUnitPrice(ev.target.value)}
            />
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button color="error" onClick={close}>
          Cancelar
        </Button>
        <Button onClick={() => AddNewBeers()}>Salvar</Button>
      </DialogActions>
    </Dialog>
  );
}

export default EditEventBeer;
